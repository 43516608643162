import React from 'react'
import './contacts-card.css'

export default function ContactsCard() {
  return (
    <div className='contacts-card'>
        <div>A</div>
        <p>Айдай</p>
    </div>
  )
}
