import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const faqs = createSlice({
    name: "faqs",
    initialState: {
        faqs: [],
        faqs_text: []
    },
    reducers: {
        getFaqs(state, action) {
            state.faqs = action.payload.faqs
            state.faqs_text = action.payload.faqs_text
        }
    },
});

export const { getFaqs } = faqs.actions;
export default faqs.reducer;

export const fetchFaqs = () => {
    return async (dispatch) => {
        try {
            const faqs = await axios.get('https://lending.neocoin.io/api/v1/faqs/')
            const faqs_text = await axios.get('https://lending.neocoin.io/api/v1/faqs/text/')
            dispatch(getFaqs({ faqs: faqs.data, faqs_text: faqs_text.data }))
        } catch (error) {
            console.log(error)
        }
    }
}