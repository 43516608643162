import EmailCode from "./pages/auth/email-code/email-code.jsx";
import SignIn from "./pages/auth/sign-in/sign-in.jsx";
import SignUp from "./pages/auth/sign-up/sign-up.jsx";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import LayOut from "./containers/layout/lay-out.jsx";
import ForgotPassword from "./pages/auth/forgot-password/forgot-password.jsx";
import { SkeletonTheme } from "react-loading-skeleton";
import WaitingProcess from "./pages/payments/components/waiting/waitng-process.jsx";
import SuccessPayments from "./pages/payments/components/success-payments/success-payments.jsx";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NotificationPage from "./pages/notification/notification.jsx";
import { ToastContainer } from "react-toastify";
import QrDetails from "./pages/home-page/components/QR code/qr-details/qr-details.jsx";
import DiscountDetail from "./pages/discount/discount-detail/discount-detail.jsx";
import DiscountBuy from "./pages/discount/discount-detail/discount-buy.jsx";
import Rules from "./pages/profile/settings/rules.jsx";
import QRScanner from "./pages/home-page/components/QR code/qr-scanner/qr-scanner.jsx";
import './index.css'
import ReserveCode from "./pages/auth/reserve-code/reserve-code.jsx";
import MfaCode from "./pages/auth/email-code/mfa-code.jsx";
import MessageDetails from "./pages/messages/message_detail.jsx";
import PaymentsGeneral from "./pages/payments-general/payments-general.jsx";

function App() {
  const location = useLocation();

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleScroll();
  }, [location]);

  const PrivateRoute = ({ element }) => {
    const token = localStorage.getItem("accessToken");

    return token ? element : <Navigate to="/market" replace />;
  };

  return (
    <div className="App">
      <ToastContainer></ToastContainer>
      <SkeletonTheme baseColor="#313131" highlightColor="#525252">
        <Routes location={location}>
          <Route
            path="/qr-scanner"
            element={<PrivateRoute element={<QRScanner />} />}
          />
          <Route
            path="/details-qr/:id"
            element={<PrivateRoute element={<QrDetails />} />}
          />
          <Route
            path="/discount-detail/:id"
            element={<PrivateRoute element={<DiscountDetail />} />}
          />
          <Route
            path="/discount-detail-forsale/:id"
            element={<PrivateRoute element={<DiscountBuy />} />}
          />
          <Route
            path="/waiting"
            element={<PrivateRoute element={<WaitingProcess />} />}
          />
          <Route
            path="/success-payments"
            element={<PrivateRoute element={<SuccessPayments />} />}
          />
          <Route
            path="/notification"
            element={<PrivateRoute element={<NotificationPage />} />}
          />
          <Route path="/*" element={<LayOut />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/register/:id" element={<SignUp />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/send-code" element={<EmailCode />} />
          <Route path="/mfa-code" element={<MfaCode />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reserve-code" element={<ReserveCode />} />
          <Route path="/detail-message/:sender/:receiver" element={<MessageDetails />} />
          <Route path="/payments_general" element={<PaymentsGeneral />} />
        </Routes>
      </SkeletonTheme>
    </div>
  );
}

export default App;