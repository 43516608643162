import React, { useEffect, useState } from 'react'
import './questions-page.scss'
import Header from '../components/header/headerStore'
import Footer from '../components/footer/footer'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux'
import { fetchFaqs } from '../../../App/slice/neocoin_store/faqs'
import LoadingAnimate from '../../../UI-kit/loading'

export default function QuestionsPage() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const faqsDatas = useSelector(state => state.faqs)


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchFaqs());
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [dispatch])

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    }

    return (
        <div className='questions_page'>
            {loading && <LoadingAnimate />}
            {!loading && (
                <>
                    <Header />
                    <h5>Часто задаваемые вопросы</h5>
                    {faqsDatas.faqs_text.map((item, i) => {
                        return (
                            <div className='questions_text' key={i}>
                                <p>{item.text}</p>
                            </div>
                        )
                    })}
                    <div className='questions'>
                        {faqsDatas.faqs.map((item, i) => {
                            return (
                                <div key={i}>
                                    <h6 onClick={() => toggleAccordion(i)}>
                                        {item.title} <IoIosArrowDown style={activeIndex === 0 ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} />
                                    </h6>
                                    {activeIndex === i ? (
                                        <div className='question_accordion_block'>
                                            {
                                                item.faqs.map((item, i) => {
                                                    return (
                                                        <Accordion className='question_accordion' key={i}>
                                                            <AccordionSummary
                                                                expandIcon={<IoIosArrowDown />}
                                                                aria-controls="panel1-content"
                                                                id="panel1-header"
                                                            >
                                                                {item.answer}
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {item.question}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div style={{ height: '48px', background: '#f3f3f3', borderRadius: '10px' }}></div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <Footer />
                </>
            )}
        </div>
    )
}
