import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const management = createSlice({
    name: 'management',
    initialState: {
        mission: [],
        management: [],
        command: []
    },
    reducers: {
        getManagement(state, action) {
            state.mission = action.payload.mission
            state.management = action.payload.management
            state.command = action.payload.command
        },
    }
})

export const { getManagement } = management.actions
export default management.reducer

export const fetchManagement = () => {
    return async (dispatch) => {
        try {
            const mission = await axios.get('https://lending.neocoin.io/api/v1/our-mission/')
            const management = await axios.get('https://lending.neocoin.io/api/v1/managment/')
            const command = await axios.get('https://lending.neocoin.io/api/v1/soon-about-command/')
            dispatch(getManagement({ mission: mission.data, management: management.data, command: command.data }))
        } catch (error) {
            console.log(error);
        }
    }
}