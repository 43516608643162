import React, { useEffect, useState } from 'react'
import './how-neocoin-works.scss'
import Header from '../components/header/headerStore'
import Footer from '../components/footer/footer'
import axios from 'axios'
import LoadingAnimate from '../../../UI-kit/loading'

export default function HowNeocoinWorks() {

    const [datas, setDatas] = useState([])
    const [loading, setLoading] = useState(false)
    const fetchHowNeocoinWorks = async () => {
        try {
            const response = await axios.get('https://lending.neocoin.io/api/v1/how-work-neo')
            setDatas(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await fetchHowNeocoinWorks();
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <Header />
            <div className='how_neocoin_works'>
                <h5>КАК РАБОТАЕТ NEO COIN</h5>
                {loading && <LoadingAnimate />}
                {!loading && datas.map((item, i) => (
                    <div className='how_neocoin_works_block' key={i}>
                        <div>
                            <img src={item.image} alt="" />
                        </div>
                        <p>{item.text}</p>
                    </div>
                ))}

                <h5 style={{textTransform: 'none'}}>МЕХАНИЗМ КОНСЕНСУСА: Proofe of stake</h5>
                <div className='how_neocoin_works_consensus'>
                    <div>
                        <p>Консенсусный механизм:
                            <span>Подтверждение транзакций.</span>
                        </p>
                    </div>
                    <div>
                        <p>
                            Валидаторы:
                            <span>Зависит от количества монет.</span>
                        </p>
                    </div>
                    <div>
                        <p>
                            Энергоэффективность:
                            <span>Меньше ресурсов, чем PoW.</span>
                        </p>
                    </div>
                </div>
                <div className='how_neocoin_works_bloks'>
                    {
                        howNeocoinWorksDatas.map((item, i) => {
                            return (
                                <div key={i}>
                                    <hr />
                                    <div className='how_neocoin_works_bloks_item'>
                                        <div>
                                            <div>{item.id}.</div>
                                            <p>{item.title}</p>
                                        </div>
                                        <span>{item.description}</span>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

const howNeocoinWorksDatas = [
    {
        id: 1,
        title: 'Эмиссия и предложение',
        description: 'Новые монеты эмитируются через валидаторов, которые ставят свои токены в сети.'
    },
    {
        id: 2,
        title: 'Торговля и ликвидность',
        description: 'Мы поддерживаем ликвидность на популярных биржах, таких как PancakeSwap.'
    },
    {
        id: 3,
        title: 'Кошельки',
        description: 'Пользователи могут хранить NEO Coin в различных кошельках, включая аппаратные и программные решения.'
    },
    {
        id: 4,
        title: 'Комиссии',
        description: 'Комиссии за транзакции минимальны, что делает нашу криптовалюту доступной для всех.'
    },
    {
        id: 5,
        title: 'Примеры использования',
        description: 'Реальные сценарии использования включают оплату товаров и услуг, а также разработку децентрализованных приложений.'
    },
    {
        id: 6,
        title: 'Сообщество и разработка',
        description: 'Наша команда активно работает над проектом, и сообщество может участвовать в его развитии через голосования и предложения по улучшениям.'
    }
]
