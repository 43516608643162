import React, { useState } from 'react'
import './payments-general.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../../containers/header/header'
import { IoIosSearch } from 'react-icons/io'
import ContactsCard from './components/contacts-card/contacts-card';
import { FaPhoneAlt } from "react-icons/fa";
import Slider from 'react-slick';

export default function PaymentsGeneral() {

  const [inputValue, setInputValue] = useState('');

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false
  };

  return (
    <div>
      <Header />
      <div className='payments_general'>
        <div className='payments_general_balance'>
          <div>
            <p>Баланс</p>
            <h6>0 KGS</h6>
          </div>
          <span>Пополнить</span>
        </div>
        <div className='payments_general_search'>
          <div className='search-bar'>
            <IoIosSearch size={12} />
            <input
              type="text"
              placeholder='Поиск'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <span>История</span>
        </div>
        <div>
          <h4>Переводы по номеру телефона</h4>
          <Slider {...settings} className='slider-container services-slider'>
            <div className='contacts-card'>
              <div>
                <FaPhoneAlt size={24} color='#e22717' />
              </div>
              <p>По номеру <br /> телефона</p>
            </div>
            <ContactsCard />
            <ContactsCard />
            <ContactsCard />
            <ContactsCard />
            <ContactsCard />
            <ContactsCard />
            <ContactsCard />
            <ContactsCard />
          </Slider>
        </div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
