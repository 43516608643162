import React, { useCallback, useEffect, useState } from 'react'
import './bonuses-and-statuses.css'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@mui/material';
import standart from '../../views/disc/standart.png'
import bronze from '../../views/disc/bronze.png'
import silver from '../../views/disc/silver.png'
import gold from '../../views/disc/gold.png'
import vip from '../../views/disc/vip.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../App/slice/user-info';
import { fetchStatusData } from '../../App/slice/status';
import { CiSquareCheck } from "react-icons/ci";
import threeStar from '../../views/disc/threeStar.svg'
import ServicesSlider from '../home-page/components/services/services-slider';
import coin from '../../views/status/coin.png'
import coin1 from '../../views/status/coin1.png'
import coin2 from '../../views/status/coin2.png'
import coin3 from '../../views/status/coin3.png'
// import { fetchNeoStatuses } from '../../App/slice/neo_statuses';

export default function BonusesAndStatuses() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const getStatus = useSelector(state => state.status.status)
    const userData = useSelector(state => state.user_info.user_info)
    // const neoStatuses = useSelector(state => state.neoStatuses.neoStatuses)

    // console.log(neoStatuses, 'neoStatuses');


    const fetchData = useCallback(() => {
        dispatch(fetchUserData());
        dispatch(fetchStatusData());
        // dispatch(fetchNeoStatuses());
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const statuses = [
        { name: 'NEO-4', image: standart, value: getStatus.neo_4 || 0 },
        { name: 'NEO-3', image: bronze, value: getStatus.neo_3 || 0 },
        { name: 'NEO-2', image: silver, value: getStatus.neo_2 || 0 },
        { name: 'NEO-1', image: gold, value: getStatus.neo_1 || 0 },
        { name: 'NEO', image: vip, value: getStatus.neo || 0 }
    ];

    useEffect(() => {
        if (userData && Object.keys(userData).length > 0 && getStatus && Object.keys(getStatus).length > 0) {
            setLoading(false);
        }
    }, [userData, getStatus]);

    return (
        <div className='BonusesAndStatuses_container'>
            <div>
                <FiChevronLeft color='#fff' onClick={() => navigate('/')} size={36} />
                <span>Бонусы и статусы</span>
            </div>
            <div>
                <h1>Ваш статус!</h1>
                {/* <h2 className='status-user'>{userData.status || "загрузка"} <GiLaurelsTrophy /> </h2> */}
                <div className='status'>
                    {loading ? (
                        <ul className='skeleton-status'>
                            {[...Array(5)].map((_, index) => (
                                <div key={index}>
                                    <Skeleton sx={{ bg: 'grey.300' }} width={20} height={5} variant="rounded" />
                                    <Skeleton sx={{ bg: 'grey.300' }} width={30} height={40} variant="rounded" />
                                    <Skeleton sx={{ bg: 'grey.300' }} width={20} height={5} variant="rounded" />
                                </div>
                            ))}
                        </ul>
                    ) : (
                        statuses.map((status, index) => (
                            <div key={index} className={userData.status === status.name ? "active_status" : ""}>
                                <h1>
                                    <span>{status.name}</span>
                                    <img width={45} height={45} src={status.image} alt="" />
                                </h1>
                                <p>{status.value}</p>
                            </div>
                        ))
                    )}
                </div>
                <div className='tasks'>
                    <div>
                        <CiSquareCheck size={24} color='#e22717' />
                        <p>Задания</p>
                    </div>
                    <div>
                        <img src={threeStar} alt="" />
                        <p>Награды</p>
                    </div>
                </div>
                <ServicesSlider />
                <div className='bonuses'>
                    <div className='balance'>
                        <p>Ваш баланс: <br /> 8 бонусов</p>
                    </div>
                    <div className='money'>
                        <div>
                            <img src={coin} alt="" />
                            <img src={coin1} alt="" />
                        </div>
                        <div>
                            <img src={coin2} alt="" />
                            <img src={coin3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
