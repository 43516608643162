import React from 'react'
import './footer.scss'
import { BiLogoInstagram } from 'react-icons/bi'
import { RiTelegramLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <hr style={{ border: '1px solid #d9d9d9'}} />
            <div className='store-footer'>
                <div>
                    <NavLink className={'store-footer-logo'} to={'/store'}>N</NavLink>
                    <ul>
                        <li><NavLink to={'#'}>О нас</NavLink></li>
                        <li><NavLink to={'#'}>Контакты</NavLink></li>
                        <li><NavLink to={'#'}>Roadmap</NavLink></li>
                        <li><NavLink to={'#'}>Дисклеймер</NavLink></li>
                        <li><NavLink to={'#'}>White Paper</NavLink></li>
                    </ul>
                    <div className='store-footer-social'>
                        <NavLink target='_blank' to={'https://instagram.com/neocoin.io'}><BiLogoInstagram size={24} /> Instagram</NavLink>
                        <NavLink target='_blank' to={'https://t.me/neocoin_io'}><RiTelegramLine size={24} /> Telegram</NavLink>
                    </div>
                </div>
                <div>
                    <p>NEO Coin - народная криптовалюта всех стран мира</p>
                    <span>© 2024, NEO</span>
                </div>
            </div>
        </div>
    )
}
