import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './services-slider.css'
import ServisesSlickDetail from './components/servises-slick-detail';

export default function ServicesSlider() {

    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false
    };

    return (
        <div className='slider-container'>
            <Slider {...settings} className='services-slider'>
                    <ServisesSlickDetail />
                    <ServisesSlickDetail />
            </Slider>
        </div>
    )
}
