import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const neoStatuses = createSlice({
    name: 'neo_statuses',
    initialState: {
        neo_statuses: []
    },
    reducers: {
        getNeoStatuses: (state, action) => {
            state.neo_statuses = action.payload
        }
    }
})

export const { getNeoStatuses } = neoStatuses.actions
export default neoStatuses.reducer

export const fetchNeoStatuses = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('https://neofund.pro/api/v1/common/neo_statuses/')
            dispatch(getNeoStatuses(response.data))
        } catch (error) {
            console.log(error);
        }
    }
}