import React, { useEffect, useState } from 'react'
import './management-page.scss'
import Header from '../components/header/headerStore'
import Footer from '../components/footer/footer'
import { AiOutlineSafety } from "react-icons/ai";
import halal from '../../../views/neocoin.store/halal.svg'
import moneyBag from '../../../views/neocoin.store/moneyBag.svg'
import { GoGoal } from "react-icons/go";
import axios from 'axios';
import LoadingAnimate from '../../../UI-kit/loading';

export default function ManagementPage() {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [datas, setDatas] = useState([]);

    const fetchDatas = async () => {
        setLoading(true);
        try {
            const mission = await axios.get('https://lending.neocoin.io/api/v1/our-mission/')
            const management = await axios.get('https://lending.neocoin.io/api/v1/managment/')
            const command = await axios.get('https://lending.neocoin.io/api/v1/soon-about-command/')
            setDatas([mission.data, management.data, command.data]);
        } catch (error) {
            console.log(error);
            setError('Ошибка загрузки данных. Пожалуйста, попробуйте позже.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchDatas();
    }, []);

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className='store_management'>
            {loading && <LoadingAnimate />}
            {!loading && (
                <>
                    <Header />
                    <h5>Наша миссия</h5>
                    <div className='store_management_text'>
                        <div>
                            {datas[0] && Array.isArray(datas[0]) ? (
                                datas[0].map((item, index) => (
                                    <p key={index}>{item.text}</p>
                                ))
                            ) : (
                                <p>Данные отсутствуют.</p>
                            )}
                        </div>
                    </div>
                    <h5>ценности</h5>
                    <div className='store_values'>
                        <div>
                            <div>
                                <img src={halal} alt="" />
                                <span>Халяльность</span>
                            </div>
                            <p>Соблюдение принципов и правил исламского Шариата во всех операциях</p>
                        </div>
                        <div>
                            <div>
                                <AiOutlineSafety />
                                <span>НАДЁЖНОСТЬ</span>
                            </div>
                            <p>Открытость данных о финансовых операциях в приложении</p>
                        </div>
                        <div>
                            <div>
                                <img src={moneyBag} alt="" />
                                <span>Доходность</span>
                            </div>
                            <p>Стабильная доходность при условиях низких рисков</p>
                        </div>
                        <div>
                            <div>
                                <GoGoal />
                                <span>КлиентоориеН<wbr />тированность</span>
                            </div>
                            <p>Уникальные условия инвестирования, отзывчивые консультанты</p>
                        </div>
                    </div>
                    <h5>РУКОВОДСТВО</h5>
                    <div className='store_management_team'>
                        {datas[1] && Array.isArray(datas[1]) ? (
                            datas[1].map((item, i) => (
                                <div key={i} className='store_management_team_item'>
                                    <div>
                                        <img src={item.image} alt="" />
                                    </div>
                                    <span>{item.full_name}</span>
                                    <p>{item.position}</p>
                                </div>
                            ))
                        ) : (
                            <p>Данные о руководстве отсутствуют.</p>
                        )}
                    </div>
                    <h5>О НАШЕЙ КОМАНДЕ</h5>
                    <div className='store_our_team'>
                        {datas[2] && Array.isArray(datas[2]) ? (
                            datas[2].map((item, i) => (
                                <p key={i}>{item.text}</p>
                            ))
                        ) : (
                            <p>Данные отсутствуют.</p>
                        )}
                    </div>
                    <Footer />
                </>
            )}
        </div>
    )
}
