import React, { useEffect, useState } from 'react'
import Header from '../components/header/headerStore'
import './store-home-page.scss'
import PartnersCard from '../components/cards/partners/partners-card'
import Button from '../components/cards/button/button'
import Advantages from '../components/cards/advantages/advantages'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import map from '../../../views/neocoin.store/map.png'
import FeaturesCard from '../components/cards/features/features-card'
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import Calculator from '../components/cards/calculator/calculator'
import Footer from '../components/footer/footer'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNeocoinStore } from '../../../App/slice/neocoin_store/neocoin_store'
import axios from 'axios'
import LoadingAnimate from '../../../UI-kit/loading'
import { IoClose } from 'react-icons/io5'

export default function StoreHomePage() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [targetModal, setTargetModal] = useState(false)
    const [active, setActive] = useState(1)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    const [targetChange, setTargetChange] = useState({
        img: null,
        title: ''
    })
    const neocoinDatas = useSelector(state => state.neocoin_store)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchNeocoinStore());
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('image', targetChange.img);
            formData.append('title', targetChange.title);
            await axios.post('https://lending.neocoin.io/api/v1/target/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setTargetModal(false)
        } catch (error) {
            console.error("Ошибка отправки цели:", error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTargetModal = () => {
        setTargetModal(!targetModal)
    }

    function SampleNextArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className='next-arrow'
                style={{
                    ...style,
                    position: 'absolute',
                    top: '295px',
                    left: '48%',
                    cursor: 'pointer',
                    display: isMobile ? 'none' : 'inline-block',
                }}
                onClick={onClick}
            ><IoIosArrowDropright size={40} /></div>
        );
    }

    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className='prev-arrow'
                style={{
                    ...style,
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '295px',
                    left: '45%',
                    display: isMobile ? 'none' : 'inline-block',
                }}
                onClick={onClick}
            ><IoIosArrowDropleft size={40} /></div>
        );
    }

    const settings = {
        className: "slider variable-width",
        dots: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false
    };

    const setting = {
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />
    };

    return (
        <div className='store-home'>
            {loading && <LoadingAnimate />}
            {!loading && (
                <>
                    {targetModal && <div className='target_modal_bg'></div>}
                    <Header />
                    <div className='store-home-start'>
                        {neocoinDatas.banner.map((item, index) => (
                            <div key={index} className='store-home-start-content'>
                                <div>
                                    <h1>{item.title}</h1>
                                    <div>
                                        <p>{item.text}</p>
                                        <Button onclick={() => navigate('/management')} />
                                    </div>
                                </div>
                                <div>
                                    <img src={item.image} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                    <h5>На что вы хотите копить?</h5>
                    <div className='store-home-target'>
                        <p>Цель поможет вам отслеживать прогресс</p>
                        <div className='store-home-target-slider'>
                            {neocoinDatas.target.map((item, index) => (
                                <div key={index}>
                                    <div>
                                        <img src={item.image} alt="" />
                                    </div>
                                    <span>{item.title}</span>
                                </div>
                            ))}
                            <div onClick={handleTargetModal}>
                                Добавьте <br /> свою цель <br /> +
                            </div>
                        </div>
                        {targetModal && (
                            <div className='target_modal'>
                                <IoClose size={24} style={{ cursor: 'pointer' }} onClick={handleTargetModal} />
                                <form action="" onSubmit={handleSubmit}>
                                    <input type="file"
                                        onChange={(e) => setTargetChange({ ...targetChange, img: e.target.files[0] })} />
                                    <input type="text"
                                        placeholder='Название цели'
                                        value={targetChange.title}
                                        onChange={(e) => setTargetChange({ ...targetChange, title: e.target.value })} />
                                    {targetChange.img === null && targetChange.title === '' ? (
                                        <p>Пожалуйста, заполните все поля</p>
                                    ) : null}
                                    <button
                                        type="submit"
                                        className='target_modal_button'
                                        disabled={targetChange.title === '' || targetChange.img === null}
                                    >
                                        {loading ? <LoadingAnimate /> : 'Добавить'}
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                    <h5>ПАРТНЁРЫ</h5>
                    <div className='partners'>
                        {neocoinDatas.partners.map((item, index) => (
                            <PartnersCard key={index}
                                img={item.image}
                            />
                        ))}
                    </div>
                    <h5>Что такое NEO Coin?</h5>
                    <div className='what'>
                        <div>
                            <p>NEO Coin – это безинфляционная суверенная товарная криптовалюта!</p>
                        </div>
                        <div>
                            <p>"NEO Coin" – это единственная полезная, профессиональная, глобальная Адал система для приумножения своих средств!</p>
                            <Button onclick={() => navigate('/management')} />
                        </div>
                    </div>
                    <div className='advantages-title'>
                        <h2 style={active === 1 ? { color: '#1a1a1a' } : { color: '#9d9d9d' }}
                            onClick={() => setActive(1)}>
                            Преимущества NEO Coin
                        </h2>
                        <h2 style={active === 2 ? { color: '#1a1a1a' } : { color: '#9d9d9d' }}
                            onClick={() => setActive(2)}>
                            / Как использовать
                        </h2>
                    </div>
                    {active === 1 ? (
                        <Slider {...settings} className='slider-container services-slider'>
                            {neocoinDatas.advantages.map((item, index) => (
                                <Advantages key={index}
                                    title={item.title}
                                    description={item.text}
                                />
                            ))}
                        </Slider>
                    ) : active === 2 && (
                        <Slider {...settings} className='slider-container services-slider'>
                            {neocoinDatas.howToUse.map((item, index) => (
                                <Advantages key={index}
                                    title={item.title}
                                    description={item.text}
                                />
                            ))}
                        </Slider>
                    )}
                    <h5>Соответствие шариату</h5>
                    <div className='store-shariat'>
                        <p>Соответствие принципам Исламских финансов подтверждено Советом <br />
                            Мусульман Кыргызстана, а также малазийским шейхом Д. Зиядом <br /> Мухамедом</p>
                        <Button />
                    </div>
                    <h5>Дорожная карта</h5>
                    <div className='store-map'>
                        <div className='store-map-img'>
                            <img src={map} alt="" />
                        </div>
                        {neocoinDatas.roadmap.map((item, index) => (
                            <div className='store-map-content' key={index}>
                                <div className='store-map-box1'>
                                    <p>{item.quarter} <br /> {item.year}</p><hr />
                                </div>
                                <div className='store-map-box2'>
                                    <p>{item.tasks}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h5>ПЛАТФОРМА "NEO"</h5>
                    <div className='store-express'>
                        <p>NEO – это глобальная универсальная образовательная, финансовая, технологическая и медиа-платформа развития!</p>
                        <div className='store-express-content'>
                            <span>Почему наша Платформа уникальна?</span>
                            <div className='store-express-text'>
                                <p>Наша масштабная Платформа "NEO" выделяется не только своим глобальным охватом, но и удобством использования криптовалюты NEO Coin для обеспечения безопасных и эффективных транзакций в любой точке планеты!</p>
                                <p>Наша Платформа – это глобальная торговая платформа, где продавцы и покупатели со всего мира могут встречаться, торговать и обмениваться товарами и услугами!</p>
                            </div>
                        </div>
                    </div>
                    <h5>ОСОБЕННОСТИ ПЛАТФОРМЫ "NEO"</h5>
                    <Slider {...setting} className='slider-container services-slider'>
                        {neocoinDatas.peculiarities.map((item, index) => (
                            <FeaturesCard key={index}
                                icon={item.image}
                                title={item.title}
                                description={item.text}
                            />
                        ))}
                    </Slider>
                    <h5>калькулятор доходности</h5>
                    <div>
                        <Calculator />
                    </div>
                    <Footer />
                </>
            )}
        </div>
    )
}
