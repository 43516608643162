import { configureStore } from '@reduxjs/toolkit'
import user_info from './slice/user-info.jsx'
import status from './slice/status.jsx'
import process from './slice/process.jsx'
import notification from './slice/notification.jsx'
import discount from './slice/discount.jsx'
import category from './slice/category.jsx'
import filter from './slice/market2/filter.jsx'
import tab from './slice/tab.jsx'
import catChoices from './slice/cat-choices.jsx'
import cateSlice from './slice/market/category-tabs.jsx'
import all_dataPostSlice from './slice/market2/all-datas.jsx'
import all_dataCategorySlice from './slice/market2/category-datas.jsx'
import myAds from './slice/market2/myAds.jsx'
import messagedetail from './slice/chat/detail-message.jsx'
// import marketReducer from './slice/market/all-datas'
import neoStatuses from './slice/neo_statuses.jsx'
import neocoin_store from './slice/neocoin_store/neocoin_store.jsx'
import faqs from './slice/neocoin_store/faqs.jsx'
import management from './slice/neocoin_store/management.jsx'

export const store = configureStore({
    reducer: {
        user_info,
        status,
        process,
        notification,
        discount,
        category,
        filter,
        tab,
        catChoices,
        cateSlice,
        all_dataPostSlice,
        all_dataCategorySlice,
        myAds,
        message_details: messagedetail,
        neoStatuses,
        neocoin_store,
        faqs,
        management
    }
})