import React from 'react'
import './expenses.css'
import { FiChevronLeft, FiSearch } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { CiCalendar } from "react-icons/ci";

export default function Expenses() {

  const navigate = useNavigate()

  return (
    <div className='expenses_container'>
      <div>
        <FiChevronLeft color='#fff' onClick={() => navigate('/')} size={36} />
        <span>Расходы</span>
      </div>
      <div className='expenses_container__search'>
        <div>
          <FiSearch color='#d9d9d9' size={24} />
          <input type="text" placeholder='Поиск' />
        </div>
        <CiCalendar color='d9d9d9' size={24} />
      </div>
    </div>
  )
}
