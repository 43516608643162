
const routes = [
    {
        path: '/',
        icon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1302 15.1936V9.4912C13.1328 8.62971 13.4761 7.8042 14.0853 7.19503C14.6945 6.58586 15.52 6.24252 16.3814 6.23999C17.2429 6.24252 18.0684 6.58586 18.6776 7.19503C19.2867 7.8042 19.6301 8.62971 19.6326 9.4912V15.1936M10.0486 12.9824H22.7142L24.0902 23.6384C24.1221 23.8844 24.1011 24.1344 24.0287 24.3717C23.9562 24.6089 23.834 24.828 23.6701 25.0143C23.5063 25.2006 23.3046 25.3497 23.0785 25.4518C22.8524 25.5539 22.6071 25.6066 22.3591 25.6064H10.4039C10.1558 25.6066 9.9105 25.5539 9.6844 25.4518C9.45831 25.3497 9.2566 25.2006 9.09274 25.0143C8.92889 24.828 8.80667 24.6089 8.73423 24.3717C8.66178 24.1344 8.64078 23.8844 8.67265 23.6384L10.0486 12.9824Z" stroke="#1A1A1A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        activeIcon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.133 12.3393C13.3028 12.3393 13.4655 12.4067 13.5855 12.5267C13.7056 12.6467 13.773 12.8096 13.773 12.9793V9.96805C13.773 9.62532 13.8408 9.28605 13.9727 8.96969C14.1045 8.65333 14.2976 8.36614 14.541 8.12484C14.7826 7.88204 15.0698 7.68947 15.3862 7.5582C15.7025 7.42694 16.0417 7.35962 16.3842 7.36008C16.7266 7.36015 17.0657 7.42775 17.382 7.55898C17.6982 7.69022 17.9855 7.88247 18.2274 8.12484C18.7163 8.61402 18.9923 9.27644 18.9954 9.96805V12.9793C18.9954 12.8096 19.0629 12.6467 19.1829 12.5267C19.3029 12.4067 19.4657 12.3393 19.6354 12.3393C19.8051 12.3393 19.9679 12.4067 20.088 12.5267C20.208 12.6467 20.2754 12.8096 20.2754 12.9793V9.96805C20.2745 8.93714 19.8647 7.94872 19.1357 7.21977C18.4067 6.49081 17.4183 6.08093 16.3874 6.08008C15.3565 6.08177 14.3682 6.49186 13.6389 7.22055C12.9096 7.94923 12.4988 8.93711 12.4962 9.96805V12.9793C12.4962 12.8101 12.5632 12.6478 12.6825 12.5279C12.8018 12.408 12.9639 12.3401 13.133 12.3393Z" fill="url(#paint0_linear_1388_2325)" />
            <path d="M10.4066 25.6065H22.3618C22.698 25.6042 23.0199 25.4698 23.2578 25.2321C23.3747 25.1154 23.4674 24.9766 23.5305 24.824C23.5937 24.6713 23.626 24.5077 23.6258 24.3425C23.6289 24.2881 23.6289 24.2337 23.6258 24.1793L22.3042 13.9393H20.285V14.6305C20.285 14.8002 20.2176 14.963 20.0976 15.083C19.9775 15.2031 19.8147 15.2705 19.645 15.2705C19.4752 15.2705 19.3125 15.2031 19.1925 15.083C19.0725 14.963 19.005 14.8002 19.005 14.6305V13.9393H13.7826V14.6305C13.7826 14.8002 13.7152 14.963 13.5951 15.083C13.4751 15.2031 13.3123 15.2705 13.1426 15.2705C12.9728 15.2705 12.8101 15.2031 12.6901 15.083C12.57 14.963 12.5026 14.8002 12.5026 14.6305V13.9393H10.4802L9.16177 24.1793C9.15701 24.2336 9.15701 24.2882 9.16177 24.3425C9.164 24.6756 9.29606 24.9948 9.52982 25.2321C9.76297 25.4652 10.077 25.5992 10.4066 25.6065Z" fill="url(#paint1_linear_1388_2325)" />
            <defs>
                <linearGradient id="paint0_linear_1388_2325" x1="16.3932" y1="6.08008" x2="16.3932" y2="25.6065" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint1_linear_1388_2325" x1="16.3932" y1="6.08008" x2="16.3932" y2="25.6065" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
            </defs>
        </svg>
    },
    {
        path: '/home',
        icon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2009 26.4513H21.8633C22.6087 26.4513 23.3237 26.1554 23.8511 25.6286C24.3785 25.1018 24.6752 24.3871 24.6761 23.6417V13.2385M8.54167 13.2385V23.6417C8.54167 24.0109 8.61444 24.3765 8.75584 24.7176C8.89724 25.0587 9.10448 25.3686 9.36571 25.6295C9.62694 25.8905 9.93705 26.0973 10.2783 26.2383C10.6195 26.3794 10.9852 26.4517 11.3545 26.4513H14.0169V20.2689C14.0164 20.1396 14.0415 20.0115 14.0907 19.892C14.1399 19.7724 14.2122 19.6637 14.3035 19.5722C14.3947 19.4806 14.5032 19.408 14.6226 19.3584C14.742 19.3088 14.87 19.2833 14.9993 19.2833H18.1993C18.4607 19.2833 18.7114 19.3871 18.8962 19.572C19.081 19.7568 19.1849 20.0075 19.1849 20.2689V26.4513M27.7545 16.5473L18.5225 6.44171C18.2792 6.17424 17.9828 5.96052 17.6522 5.81429C17.3215 5.66806 16.964 5.59253 16.6025 5.59253C16.2409 5.59253 15.8834 5.66806 15.5528 5.81429C15.2221 5.96052 14.9257 6.17424 14.6825 6.44171L5.44727 16.5473" stroke="#1A1A1A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        activeIcon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.9606 12.8812L18.8326 6.16123C18.5301 5.82901 18.1615 5.56371 17.7504 5.3824C17.3393 5.20109 16.8947 5.10777 16.4454 5.10842C15.9964 5.10683 15.5521 5.19977 15.1414 5.38115C14.7306 5.56254 14.3628 5.82832 14.0615 6.16123L4.82948 16.2636C4.77243 16.3255 4.72812 16.3981 4.69917 16.4772C4.67021 16.5562 4.65716 16.6402 4.66073 16.7244C4.6643 16.8085 4.6844 16.8911 4.71995 16.9674C4.7555 17.0437 4.80583 17.1123 4.86792 17.1692C4.93005 17.2261 5.00264 17.2701 5.08183 17.2988C5.16101 17.3275 5.24519 17.3403 5.32933 17.3364C5.41346 17.3325 5.49592 17.3121 5.57214 17.2762C5.64836 17.2404 5.71673 17.19 5.77339 17.1276L8.37823 14.2764V23.7932C8.37823 24.1624 8.45105 24.528 8.59245 24.8691C8.73384 25.2102 8.94106 25.5201 9.20229 25.781C9.46353 26.0419 9.7737 26.2488 10.1149 26.3898C10.4562 26.5308 10.8218 26.6032 11.191 26.6028H13.8534V20.4172C13.8534 20.1559 13.9573 19.9051 14.1421 19.7203C14.327 19.5355 14.5776 19.4316 14.839 19.4316H18.039C18.3004 19.4316 18.5512 19.5355 18.736 19.7203C18.9209 19.9051 19.0246 20.1559 19.0246 20.4172V26.6028H21.687C22.4324 26.6028 23.1474 26.3069 23.6748 25.7801C24.2022 25.2533 24.4989 24.5386 24.4998 23.7932V14.2764L27.1079 17.1276C27.2222 17.2525 27.3813 17.327 27.5504 17.3348C27.7195 17.3426 27.8849 17.283 28.0103 17.1692C28.0726 17.1125 28.123 17.0442 28.1589 16.968C28.1947 16.8917 28.2151 16.8092 28.219 16.7251C28.2229 16.6409 28.21 16.5568 28.1814 16.4776C28.1527 16.3985 28.1087 16.3257 28.0518 16.2636L24.9606 12.8812Z" fill="url(#paint0_linear_643_1414)" />
            <defs>
                <linearGradient id="paint0_linear_643_1414" x1="16.4399" y1="5.1084" x2="16.4399" y2="26.6028" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
            </defs>
        </svg>
    },
    {
        path: '/qr-scanner',
        icon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.14063 10.4575V6.68469C5.14062 6.4159 5.19376 6.14974 5.29672 5.90145C5.39968 5.65315 5.55056 5.42759 5.74078 5.23767C5.931 5.04775 6.1567 4.8972 6.40516 4.79463C6.65361 4.69205 6.91995 4.63947 7.18875 4.6399H11.4031M11.4031 27.3055H7.18875C6.91995 27.3059 6.65361 27.2533 6.40516 27.1508C6.1567 27.0482 5.931 26.8976 5.74078 26.7077C5.55056 26.5178 5.39968 26.2922 5.29672 26.0439C5.19376 25.7956 5.14062 25.5295 5.14063 25.2607V20.5983M21.547 4.6399H25.7616C26.0304 4.63947 26.2965 4.69205 26.545 4.79463C26.7935 4.8972 27.0193 5.04775 27.2095 5.23767C27.3997 5.42759 27.5506 5.65315 27.6536 5.90145C27.7566 6.14974 27.8095 6.4159 27.8095 6.68469V10.4575M27.8095 20.5983V25.2607C27.8095 25.5295 27.7566 25.7956 27.6536 26.0439C27.5506 26.2922 27.3997 26.5178 27.2095 26.7077C27.0193 26.8976 26.7935 27.0482 26.545 27.1508C26.2965 27.2533 26.0304 27.3059 25.7616 27.3055H21.547M8.75349 8.6367H14.0591V13.9423H8.75349V8.6367ZM18.5839 8.6367H23.8895V13.9423H18.5839V8.6367ZM8.75349 18.1759H14.0591V23.4815H8.75349V18.1759ZM18.5839 18.1759H23.8895V23.4815H18.5839V18.1759Z" stroke="#1A1A1A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        activeIcon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.30016 11.2575C5.46989 11.2575 5.63271 11.1901 5.75273 11.0701C5.87276 10.95 5.94016 10.7872 5.94016 10.6175V6.84468C5.93963 6.6602 5.97584 6.4775 6.04672 6.30718C6.11759 6.13686 6.22172 5.98237 6.35297 5.85272C6.48391 5.72143 6.6395 5.61732 6.81086 5.54647C6.98222 5.47563 7.1659 5.43942 7.35133 5.43991H11.5658C11.7355 5.43991 11.8983 5.37251 12.0183 5.25249C12.1383 5.13247 12.2058 4.96965 12.2058 4.79991C12.2058 4.63017 12.1383 4.46736 12.0183 4.34733C11.8983 4.22731 11.7355 4.15991 11.5658 4.15991H7.35133C6.63869 4.15991 5.95514 4.44254 5.45063 4.94585C4.94611 5.44916 4.66185 6.13204 4.66016 6.84468V10.6175C4.66016 10.7872 4.72755 10.95 4.84758 11.0701C4.9676 11.1901 5.13042 11.2575 5.30016 11.2575Z" fill="url(#paint0_linear_1388_5081)" />
            <path d="M11.5658 26.8255H7.35133C7.1659 26.826 6.98222 26.7897 6.81086 26.7189C6.6395 26.648 6.48391 26.544 6.35297 26.4127C6.22172 26.2831 6.11759 26.1286 6.04672 25.9583C5.97584 25.7879 5.93963 25.6052 5.94016 25.4207V20.7583C5.94016 20.5886 5.87276 20.4258 5.75273 20.3058C5.63271 20.1857 5.46989 20.1183 5.30016 20.1183C5.13042 20.1183 4.9676 20.1857 4.84758 20.3058C4.72755 20.4258 4.66016 20.5886 4.66016 20.7583V25.4207C4.66185 26.1333 4.94611 26.8162 5.45063 27.3195C5.95514 27.8228 6.63869 28.1055 7.35133 28.1055H11.5658C11.7355 28.1055 11.8983 28.0381 12.0183 27.918C12.1383 27.798 12.2058 27.6353 12.2058 27.4655C12.2058 27.2958 12.1383 27.133 12.0183 27.013C11.8983 26.8929 11.7355 26.8255 11.5658 26.8255Z" fill="url(#paint1_linear_1388_5081)" />
            <path d="M25.9209 4.15991H21.7098C21.54 4.15991 21.3772 4.22731 21.2572 4.34734C21.1372 4.46736 21.0698 4.63018 21.0698 4.79991C21.0698 4.96965 21.1372 5.13247 21.2572 5.25249C21.3772 5.37252 21.54 5.43991 21.7098 5.43991H25.9209C26.1064 5.43907 26.2902 5.47514 26.4616 5.54601C26.633 5.61688 26.7887 5.72115 26.9194 5.85273C27.0506 5.98237 27.1547 6.13686 27.2255 6.30718C27.2964 6.4775 27.3327 6.6602 27.3322 6.84468V10.6175C27.3322 10.7872 27.3996 10.95 27.5196 11.0701C27.6396 11.1901 27.8025 11.2575 27.9722 11.2575C28.1419 11.2575 28.3047 11.1901 28.4247 11.0701C28.5447 10.95 28.6122 10.7872 28.6122 10.6175V6.84468C28.6118 6.49169 28.5418 6.14228 28.4063 5.81632C28.2709 5.49036 28.0725 5.19422 27.8226 4.94491C27.5727 4.69561 27.2761 4.49797 26.9498 4.36327C26.6236 4.22858 26.2739 4.15949 25.9209 4.15991Z" fill="url(#paint2_linear_1388_5081)" />
            <path d="M27.9722 20.1183C27.8025 20.1183 27.6396 20.1857 27.5196 20.3058C27.3996 20.4258 27.3322 20.5886 27.3322 20.7583V25.4207C27.3327 25.6052 27.2964 25.7879 27.2255 25.9583C27.1547 26.1286 27.0506 26.2831 26.9194 26.4127C26.7887 26.5443 26.633 26.6486 26.4616 26.7194C26.2902 26.7903 26.1064 26.8264 25.9209 26.8255H21.7098C21.54 26.8255 21.3772 26.8929 21.2572 27.013C21.1372 27.133 21.0698 27.2958 21.0698 27.4655C21.0698 27.6353 21.1372 27.798 21.2572 27.918C21.3772 28.0381 21.54 28.1055 21.7098 28.1055H25.9209C26.2739 28.106 26.6236 28.0368 26.9498 27.9021C27.2761 27.7674 27.5727 27.5698 27.8226 27.3205C28.0725 27.0712 28.2709 26.7751 28.4063 26.4491C28.5418 26.1232 28.6118 25.7737 28.6122 25.4207V20.7583C28.6122 20.5886 28.5447 20.4258 28.4247 20.3058C28.3047 20.1857 28.1419 20.1183 27.9722 20.1183Z" fill="url(#paint3_linear_1388_5081)" />
            <path d="M14.2186 8.31671H8.91297C8.78585 8.31751 8.66407 8.36803 8.57375 8.45749C8.48429 8.54781 8.43377 8.66959 8.43297 8.79671V14.1023C8.43308 14.2296 8.48371 14.3516 8.57375 14.4416C8.66407 14.531 8.78585 14.5815 8.91297 14.5823H14.2186C14.3459 14.5822 14.4678 14.5316 14.5577 14.4416C14.6478 14.3516 14.6985 14.2296 14.6986 14.1023V8.79671C14.6978 8.66959 14.6472 8.54781 14.5577 8.45749C14.4678 8.36745 14.3459 8.31682 14.2186 8.31671Z" fill="url(#paint4_linear_1388_5081)" />
            <path d="M18.4041 8.45749C18.3147 8.54781 18.2642 8.66959 18.2634 8.79671V14.1023C18.2635 14.2296 18.3141 14.3516 18.4041 14.4416C18.4941 14.5316 18.6161 14.5822 18.7434 14.5823H24.049C24.1762 14.5822 24.2982 14.5316 24.3881 14.4416C24.4782 14.3516 24.5289 14.2296 24.529 14.1023V8.79671C24.5282 8.66959 24.4776 8.54781 24.3881 8.45749C24.2978 8.36803 24.1761 8.31751 24.049 8.31671H18.7434C18.6162 8.31751 18.4945 8.36803 18.4041 8.45749Z" fill="url(#paint5_linear_1388_5081)" />
            <path d="M14.2186 17.8559H8.91297C8.78585 17.8567 8.66407 17.9073 8.57375 17.9967C8.48371 18.0867 8.43308 18.2087 8.43297 18.3359V23.6416C8.43377 23.7687 8.48429 23.8904 8.57375 23.9807C8.6644 24.0693 8.78623 24.1188 8.91297 24.1184H14.2186C14.2814 24.1189 14.3437 24.107 14.4019 24.0834C14.4601 24.0597 14.513 24.0249 14.5577 23.9807C14.6472 23.8904 14.6978 23.7687 14.6986 23.6416V18.3231C14.6985 18.1959 14.6478 18.0738 14.5577 17.9839C14.4657 17.8985 14.344 17.8526 14.2186 17.8559Z" fill="url(#paint6_linear_1388_5081)" />
            <path d="M24.3881 23.9807C24.4776 23.8904 24.5282 23.7687 24.529 23.6416V18.3231C24.5289 18.1959 24.4782 18.0738 24.3881 17.9839C24.2978 17.8944 24.1761 17.8439 24.049 17.8431H18.7434C18.6162 17.8439 18.4945 17.8944 18.4041 17.9839C18.3141 18.0738 18.2635 18.1959 18.2634 18.3231V23.6287C18.2642 23.7559 18.3147 23.8776 18.4041 23.9679C18.4488 24.012 18.5018 24.0469 18.56 24.0705C18.6182 24.0942 18.6805 24.1061 18.7434 24.1055H24.049C24.174 24.1101 24.2958 24.0652 24.3881 23.9807Z" fill="url(#paint7_linear_1388_5081)" />
            <defs>
                <linearGradient id="paint0_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint1_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint2_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint3_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint4_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint5_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint6_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint7_linear_1388_5081" x1="16.6362" y1="4.15991" x2="16.6362" y2="28.1055" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
            </defs>
        </svg>
    },
    {
        path: '/messages',
        icon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2613 20.6361H9.94125L5.78125 23.0393V9.66332C5.7821 8.83979 6.10983 8.0503 6.69245 7.46828C7.27507 6.88626 8.06492 6.55933 8.88845 6.55933H21.3428C21.751 6.55849 22.1553 6.63813 22.5327 6.79375C22.91 6.94936 23.2529 7.17789 23.5418 7.46621C23.8308 7.75452 24.06 8.09699 24.2164 8.474C24.3727 8.85101 24.4533 9.25516 24.4533 9.66332V10.5081M10.2613 20.6361H21.3492C22.1725 20.6361 22.962 20.3091 23.5441 19.727C24.1262 19.1449 24.4533 18.3554 24.4533 17.5321V10.5081M10.2613 20.6361C10.1921 21.1456 10.29 21.578 10.3476 21.7305C10.5633 22.2608 10.932 22.7148 11.4068 23.0345C11.8815 23.3543 12.4408 23.5253 13.0132 23.5257H23.5733L27.4133 25.7657V13.3881C27.4133 12.6243 27.1098 11.8918 26.5697 11.3517C26.0296 10.8115 25.2971 10.5081 24.5333 10.5081H24.4533" stroke="#1A1A1A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        activeIcon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.9254 11.0113V17.5296C24.9254 18.0006 24.8326 18.4669 24.6523 18.9019C24.472 19.337 24.2077 19.7322 23.8745 20.065C23.5414 20.3979 23.1459 20.6618 22.7107 20.8418C22.2755 21.0217 21.8091 21.1141 21.3381 21.1137H10.7558L10.807 21.5937C10.9913 22.0233 11.2975 22.3895 11.6876 22.6471C12.0777 22.9047 12.5347 23.0424 13.0022 23.0432H23.5622C23.6474 23.0432 23.7312 23.0653 23.8054 23.1073L26.9254 24.9153V13.3696C26.9263 13.0552 26.8648 12.7437 26.7445 12.4531C26.6242 12.1625 26.4475 11.8986 26.2245 11.6768C25.872 11.3242 25.4175 11.0914 24.9254 11.0113Z" fill="url(#paint0_linear_1388_5102)" />
            <path d="M9.93017 20.1569H21.3381C21.683 20.1568 22.0246 20.0886 22.3431 19.9563C22.6615 19.824 22.9507 19.63 23.1942 19.3857C23.4389 19.1426 23.6331 18.8535 23.7655 18.5349C23.8978 18.2163 23.9658 17.8746 23.9654 17.5297V9.66412C23.9658 9.31915 23.8978 8.97745 23.7655 8.65889C23.6331 8.34032 23.4389 8.05119 23.1942 7.80811C22.9511 7.56348 22.6619 7.36956 22.3433 7.23772C22.0247 7.10587 21.683 7.03868 21.3381 7.04006H8.88376C8.53899 7.03904 8.19742 7.10644 7.87884 7.23826C7.56026 7.37009 7.27099 7.56377 7.02775 7.80811C6.78341 8.05135 6.58973 8.34062 6.4579 8.6592C6.32608 8.97778 6.25876 9.31935 6.25978 9.66412V22.2049L9.69017 20.2209C9.76334 20.1793 9.846 20.1572 9.93017 20.1569Z" fill="url(#paint1_linear_1388_5102)" />
            <defs>
                <linearGradient id="paint0_linear_1388_5102" x1="16.5926" y1="7.04004" x2="16.5926" y2="24.9153" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
                <linearGradient id="paint1_linear_1388_5102" x1="16.5926" y1="7.04004" x2="16.5926" y2="24.9153" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
            </defs>
        </svg>
    },
    {
        path: '/payments_general',
        icon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.16493 24.4384V16.0544C8.16829 15.9172 8.2256 15.7868 8.32444 15.6916C8.42328 15.5963 8.55569 15.5439 8.69294 15.5456H11.3713C11.51 15.5456 11.643 15.6005 11.7413 15.6982C11.8396 15.7959 11.8953 15.9286 11.8961 16.0672V24.4384M11.8961 24.4384V24.4512M11.8961 24.4384H27.4737M11.8961 24.4384H5.46094M14.7089 24.4384V11.8464C14.7085 11.7776 14.7217 11.7095 14.7477 11.6458C14.7737 11.5822 14.8121 11.5243 14.8606 11.4755C14.909 11.4267 14.9667 11.388 15.0302 11.3616C15.0937 11.3352 15.1618 11.3216 15.2305 11.3216H17.9121C17.9809 11.3216 18.049 11.3352 18.1125 11.3616C18.176 11.388 18.2336 11.4267 18.2821 11.4755C18.3306 11.5243 18.3689 11.5822 18.3949 11.6458C18.421 11.7095 18.4342 11.7776 18.4337 11.8464V24.4384M21.3969 24.4384V8.04162C21.3969 7.90328 21.4519 7.77061 21.5497 7.67279C21.6475 7.57497 21.7802 7.52002 21.9185 7.52002H24.6001C24.7385 7.52002 24.8712 7.57497 24.969 7.67279C25.0668 7.77061 25.1217 7.90328 25.1217 8.04162V24.4384" stroke="#1A1A1A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        activeIcon: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.6366 23.632H25.2814V7.87836C25.2818 7.80959 25.2685 7.74139 25.2425 7.67773C25.2165 7.61408 25.1782 7.5562 25.1297 7.50742C25.0812 7.45865 25.0236 7.42001 24.9602 7.39359C24.8967 7.36718 24.8285 7.35351 24.7597 7.35352H22.0781C22.0094 7.35351 21.9413 7.36718 21.8778 7.39359C21.8143 7.42001 21.7566 7.45865 21.7081 7.50742C21.6596 7.5562 21.6213 7.61408 21.5953 7.67773C21.5693 7.74139 21.5561 7.80959 21.5566 7.87836V23.632H18.5933V11.6799C18.5933 11.5413 18.5385 11.4083 18.4408 11.31C18.3431 11.2117 18.2103 11.156 18.0717 11.1552H15.3902C15.2515 11.156 15.1188 11.2117 15.0211 11.31C14.9234 11.4083 14.8686 11.5413 14.8686 11.6799V23.632H12.0494V15.8912C12.0498 15.8224 12.0366 15.7542 12.0106 15.6905C11.9846 15.6269 11.9461 15.569 11.8977 15.5202C11.8492 15.4715 11.7916 15.4327 11.7281 15.4063C11.6646 15.3799 11.5966 15.3663 11.5278 15.3663H8.84609C8.77733 15.3663 8.70927 15.3799 8.64578 15.4063C8.58229 15.4327 8.52473 15.4715 8.47625 15.5202C8.42777 15.569 8.38931 15.6269 8.36328 15.6905C8.33726 15.7542 8.32411 15.8224 8.32453 15.8912V23.632H5.62047C5.45073 23.632 5.28799 23.6994 5.16797 23.8194C5.04795 23.9394 4.98047 24.1022 4.98047 24.272C4.98047 24.4417 5.04795 24.6045 5.16797 24.7245C5.28799 24.8446 5.45073 24.912 5.62047 24.912H27.6366C27.8063 24.912 27.969 24.8446 28.0891 24.7245C28.2091 24.6045 28.2766 24.4417 28.2766 24.272C28.2766 24.1022 28.2091 23.9394 28.0891 23.8194C27.969 23.6994 27.8063 23.632 27.6366 23.632Z" fill="url(#paint0_linear_1388_5123)" />
            <defs>
                <linearGradient id="paint0_linear_1388_5123" x1="16.6285" y1="7.35352" x2="16.6285" y2="24.912" gradientUnits="userSpaceOnUse">
                    <stop offset="0.484" stop-color="#E22717" />
                    <stop offset="1" stop-color="#FFAEA7" />
                </linearGradient>
            </defs>
        </svg>

    }
]

export default routes