import React, { useEffect, useState } from "react";
import "./discount.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cate1 from "../../views/disc/Standart_home.png";
import cate2 from "../../views/disc/Bronze_home.png";
import cate3 from "../../views/disc/Silver_home.png";
import cate4 from "../../views/disc/Gold_home.png";
import cate5 from "../../views/disc/Vip_home.png";
import SkeletonDiscount from "./Skeleton";
import { fetchdiscountData } from "../../App/slice/discount";
import { FiChevronLeft } from "react-icons/fi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../home-page/components/services/services-slider.css'

const categoryImages = [cate1, cate2, cate3, cate4, cate5];

export default function Discount() {
  const names = useSelector((state) => state.user_info.user_info);
  const data = useSelector(state => state.discount.discount)
  const [cate, setCate] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchdiscountData())
  }, [])

  useEffect(() => {
    if (names) {
      setCate(names.status);
    }
  }, [names]);

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false
  };

  return (
    <div className="discount">
      <div>
        <FiChevronLeft onClick={() => navigate('/')} size={36} />
        <span>Скидки</span>
      </div>
      <div className="container_2">
        <p className="text_dis">
          Нажмите на статус, чтобы узнать, какие привилегии есть у выбранного статуса
        </p>
        <div className="cateing">
          {["NEO 4", "NEO 3", "NEO 2", "NEO 1", "NEO"].map((category, index) => (
            <div
              key={index}
              onClick={() => setCate(category)}
              className={`cate ${cate === category && "active"}`}
            >
              <img width={45} height={45} src={categoryImages[index]} alt="" />
              <h1>{category}</h1>
            </div>
          ))}
        </div>
        {data.length ? (data.map((el, key) => (
          el.partners.length > 0 ? <div key={key}>
            <h2>{el.name}</h2>
            <div className="slider-container discount_block">
              <Slider {...settings} className='services-slider'>
                {el.partners.map((item, idx) => (
                  <div key={idx}>
                    <div
                      onClick={() => navigate(`/discount-detail/${item.id}`)}
                      className="discount_box"
                    >
                      <img src={`https://neocoin.io${item.img}`} alt="" />
                    </div>
                    <p className="text_discount">{item.title}</p>
                    {cate === "NEO-4" && <p className="absolute">-{item.d_neo_4} %</p>}
                    {cate === "NEO-3" && <p className="absolute">-{item.d_neo_3} %</p>}
                    {cate === "NEO-2" && <p className="absolute">-{item.d_neo_2} %</p>}
                    {cate === "NEO-1" && <p className="absolute">-{item.d_neo_1} %</p>}
                    {cate === "NEO" && <p className="absolute">-{item.d_neo} %</p>}
                    {/* <h2 className="cost_of_visit">{item.cost_of_visit} NEO Coin</h2> */}
                    {/* <h5><IoEyeSharp /> <span> {item.views}</span></h5> */}
                  </div>
                ))}
              </Slider>
            </div>
          </div> : ""
        ))
        ) : <SkeletonDiscount />}
      </div>
    </div>
  );
}
