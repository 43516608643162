import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const neocoin_store = createSlice({
    name: 'neocoin_store',
    initialState: {
        banner: [],
        target: [],
        partners: [],
        advantages: [],
        roadmap: [],
        peculiarities: [],
        howToUse: [],
        error: null
    },
    reducers: {
        getNeocoinStore(state, action) {
            const {
                banner, target, partners, advantages, roadmap, peculiarities, howToUse
            } = action.payload;
            state.banner = banner;
            state.target = target;
            state.partners = partners;
            state.advantages = advantages;
            state.roadmap = roadmap;
            state.peculiarities = peculiarities;
            state.howToUse = howToUse;
        },
        setError(state, action) {
            state.error = action.payload
        }
    }
})

export const { getNeocoinStore, setError } = neocoin_store.actions
export default neocoin_store.reducer

export const fetchNeocoinStore = () => {
    return async (dispatch) => {
        try {
            const [
                banner, target, partners, advantages, roadmap, peculiarities, howToUse
            ] = await Promise.all([
                axios.get('https://lending.neocoin.io/api/v1/banner/'),
                axios.get('https://lending.neocoin.io/api/v1/target/'),
                axios.get('https://lending.neocoin.io/api/v1/partners/'),
                axios.get('https://lending.neocoin.io/api/v1/advantages/'),
                axios.get('https://lending.neocoin.io/api/v1/roadmap/'),
                axios.get('https://lending.neocoin.io/api/v1/peculiarities/'),
                axios.get('https://lending.neocoin.io/api/v1/how-to-use/')
            ]);

            dispatch(getNeocoinStore({
                banner: banner.data,
                target: target.data,
                partners: partners.data,
                advantages: advantages.data,
                roadmap: roadmap.data,
                peculiarities: peculiarities.data,
                howToUse: howToUse.data
            }));
        } catch (error) {
            console.error("Ошибка получения данных из Neocoin API:", error.message);
            dispatch(setError(error.message));
            if (error.response) {
                console.error("Данные ответа:", error.response.data);
                console.error("Статус ответа:", error.response.status);
            } else if (error.request) {
                console.error("Ответа не получено:", error.request);
            } else {
                console.error("Ошибка при настройке запроса:", error.message);
            }
        }
    }
}