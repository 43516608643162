import React, { useEffect, useState } from 'react'
import './calculator.scss'

export default function Calculator() {

    const [initialInvestment, setInitialInvestment] = useState(0);
    const [monthlyInvestment, setMonthlyInvestment] = useState(0);
    const [investmentPeriod, setInvestmentPeriod] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);

    const annualRate = 0.1;

    useEffect(() => {
        const totalInvestedAmount = initialInvestment + (monthlyInvestment * 12 * investmentPeriod);
        setTotalInvested(totalInvestedAmount);

        const finalAmount = totalInvestedAmount * Math.pow(1 + annualRate, investmentPeriod);
        setTotalEarned(finalAmount);
    }, [initialInvestment, monthlyInvestment, investmentPeriod]);

    useEffect(() => {
        const sliders = document.querySelectorAll('.custom-slider');

        const updateBackground = (slider) => {
            const value = slider.value;
            const max = slider.max;
            const percent = (value / max) * 100;
            slider.style.background = `linear-gradient(to right, #e22717 0%, #e22717 ${percent}%, #ddd ${percent}%)`;
        };

        sliders.forEach((slider) => {
            updateBackground(slider);
            slider.addEventListener('input', () => updateBackground(slider));
        });

        return () => {
            sliders.forEach((slider) => {
                slider.removeEventListener('input', () => updateBackground(slider));
            });
        };
    }, [initialInvestment, monthlyInvestment, investmentPeriod]);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('ru-RU').format(number);
    };


    return (
        <div className='store-calculator'>
            <div className='store-calculator_top'>
                <div className='store-calculator_top_item'>
                    <div>
                        <p>Первоначальное вложение</p>
                        <span>{formatNumber(initialInvestment)} сом</span>
                    </div>
                    <input type="range"
                        className='custom-slider'
                        min={0}
                        max={1000000}
                        value={initialInvestment}
                        onChange={(e) => setInitialInvestment(Number(e.target.value))}
                    />
                </div>
                <div className='store-calculator_top_item'>
                    <div>
                        <p>Ежемесячные инвестиции</p>
                        <span>{formatNumber(monthlyInvestment)} сом</span>
                    </div>
                    <input type="range"
                        className='custom-slider'
                        min={0}
                        max={1000000}
                        value={monthlyInvestment}
                        onChange={(e) => setMonthlyInvestment(Number(e.target.value))}
                    />
                </div>
                <div className='store-calculator_top_item'>
                    <div>
                        <p>Срок инвестирования</p>
                        <span>{investmentPeriod} лет</span>
                    </div>
                    <input type="range"
                        className='custom-slider'
                        min={0}
                        max={10}
                        value={investmentPeriod}
                        onChange={(e) => setInvestmentPeriod(Number(e.target.value))}
                    />
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <div className='store-calculator_itogs'>
                    <h3>Начало года</h3>
                    <div className='store-calculator_itogs_item'>
                        <div>
                            <p>Всего заработано</p>
                            <span>{formatNumber(totalEarned.toFixed(2))} сом</span>
                        </div>
                        <div>
                            <p>Всего инвестировано</p>
                            <span>{formatNumber(totalInvested.toFixed(2))} сом</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
